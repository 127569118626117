import { NoResults } from '@features/no-results/no-results';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { observer } from 'mobx-react-lite';
import { forwardRef, ReactNode, useEffect } from 'react';
import { Datagrid, FunctionField, useListController } from 'react-admin';

import CompanyNameField from './fields/companynameField';
import ExportCheckboxField from './fields/export-checkbox-field';
import { ICompaniesDataGrid } from './interfaces';
import { companiesStore } from './model/companies-table.store';
import { styles } from './style';
import ContactsSkeleton from './UI/contacts-skeleton';

const CompaniesDataGrid = forwardRef<HTMLTableElement | null, ICompaniesDataGrid>((props, ref) => {
  // const {
  //   //data,
  //   //buttons: { PhoneButton, EmailButton },
  // } = props

  const { isFetching, isLoading } = useListController();

  //const companiesStore = useCompaniesStore()
  console.log(props);
  const onRowClickHandler = (record: IContact) => {
    console.log(record);

    //companiesStore.setCurrentContact(record)
    return '';
  };

  if (isFetching || isLoading) {
    return <ContactsSkeleton />;
  }

  // const toggleAllForExport = () => {
  //   setChecked(false)
  //   console.log(checked)
  // }

  return (
    <Datagrid
      sx={styles}
      bulkActionButtons={false}
      rowClick={(_, _r, record) => onRowClickHandler(record as IContact)}
      stickyHeader={false}
      empty={<NoResults />}
      ref={ref}
    >
      <ExportCheckboxField
        label={<CheckboxHeader />}
        sortable={false}
        cellClassName="export-checkbox"
      />
      <CompanyNameField source="name" label="Company" sortable={false} cellClassName="company" />
      <FunctionField
        source="size"
        label="Employees"
        render={(record: Record<string, ReactNode> | undefined) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.size}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {record?.size}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
      <FunctionField
        label="Industry"
        cellClassName="industry"
        render={(record: Record<string, ReactNode> | undefined) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.industry}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {record?.industry}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
      <FunctionField
        label="Company Location"
        cellClassName="location"
        render={(record: Record<string, ReactNode> | undefined) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.location}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {record?.location}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
    </Datagrid>
  );
});

export default observer(CompaniesDataGrid);

const CheckboxHeader = observer(function () {
  //const [checked, setChecked] = useState(false)

  const handleSelectAll = () => {
    companiesStore.exportAllSelector = !companiesStore.exportAllSelector;
    companiesStore.toggleSelectAllForExport();
  };
  useEffect(() => {
    companiesStore.toggleSelectAllForExport();
  }, []);

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Checkbox
        size="small"
        checked={companiesStore.exportAllSelector}
        onChange={() => handleSelectAll()}
        disabled={!companiesStore.currentContacts}
      />
    </Stack>
  );
});
