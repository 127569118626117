import { Drawer, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { ListBase } from 'react-admin';

import { ContactDataGrid, useContactsStore } from '../../entities/contact-table';
import UpdateWrapper from '../../entities/update-wrapper/update-wrapper';
import { ContactSidebar, EmailButton, PhoneButton, UpgradePopup } from '../../features/contacts';
import { ReactComponent as CloseIcon } from '../../shared/assets/icons/iconClose.svg';
import { ContactLayout } from '../../widgets/contact-layout';

const ContactsPage = () => {
  const contactButton = {
    EmailButton: EmailButton,
    PhoneButton: PhoneButton,
  };

  const contactsStore = useContactsStore();

  const tableRef = useRef<null | HTMLTableElement>(null);

  const drawerRef = useRef<null | HTMLDivElement>(null);

  const isSideBarOpen = Boolean(contactsStore.currentContact);

  const onSideBarCloseHandler = () => {
    contactsStore.setCurrentContact(null);
  };

  useEffect(() => {
    const onOutsideClickHandler = (e: Event) => {
      if (
        tableRef.current &&
        drawerRef.current &&
        !tableRef.current.contains(e.target as HTMLElement) &&
        !drawerRef.current.contains(e.target as HTMLElement)
      ) {
        contactsStore.setCurrentContact(null);
      }
    };
    document.addEventListener('click', onOutsideClickHandler);
    return () => {
      document.removeEventListener('click', onOutsideClickHandler);
    };
  }, [contactsStore]);

  const [updating, setUpdating] = useState(false);
  const [showSaved, setShowSaved] = useState(false);

  return (
    <>
      <UpgradePopup />
      <UpdateWrapper setUpdating={setUpdating} isOpen={isSideBarOpen}>
        {showSaved ? (
          <ListBase perPage={10} resource="saved-contacts">
            <ContactLayout setShowSaved={setShowSaved} showSaved={showSaved}>
              <ContactDataGrid ref={tableRef} buttons={contactButton} />
            </ContactLayout>
          </ListBase>
        ) : (
          <ListBase perPage={10} resource="contacts">
            <ContactLayout setShowSaved={setShowSaved} showSaved={showSaved}>
              <ContactDataGrid ref={tableRef} buttons={contactButton} />
            </ContactLayout>
          </ListBase>
        )}
        <Drawer
          anchor="right"
          open={isSideBarOpen}
          onClose={onSideBarCloseHandler}
          ref={drawerRef}
          variant="persistent"
          PaperProps={{
            sx: {
              boxShadow: '-4px 0px 20px rgba(111, 113, 122, 0.2)',
              overflowY: 'visible',
            },
          }}
        >
          <IconButton
            onClick={onSideBarCloseHandler}
            sx={{
              border: '2px solid #EFF0F1',
              position: 'absolute',
              padding: 6,
              top: 8,
              left: -16,
              zIndex: 20,
              backgroundColor: 'white',
              ':hover': {
                backgroundColor: '#F5F6F9',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <ContactSidebar contact={contactsStore.currentContact!} updating={updating} />
        </Drawer>
      </UpdateWrapper>
    </>
  );
};

export default observer(ContactsPage);
